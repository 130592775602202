import React, { useState, useEffect, useContext } from "react";
import useGetData from "../../hooks/useGetData";
import _ from "underscore";
import { ListOfMenu } from "./style";
import { GrClose } from "react-icons/gr";
import { SearchContext } from "../../context/SearchContext";

const MenuCollection = ({
  closeCollectionMenu,
  reference,
  onChangeFilter,
  filter,
  handleShowMenu,
}) => {
  const { setKeySearch } = useContext(SearchContext);
  const getCollections = useGetData();
  const collections = getCollections.allGoogleSheetMenuCollection.nodes;
  const collection = _.groupBy(collections, "collection");
  const [listOfCollection, setListOfCollection] = useState([]);

  useEffect(() => {
    _.map(collection, (key, item) => {
      return setListOfCollection((prevState) => [
        ...prevState,
        { name: item, value: key },
      ]);
    });
    setKeySearch({ collection: filter.collection, item: filter.item });
  }, []);

  const handleMatchCollection = (shortLink) => {
    onChangeFilter({
      collection: shortLink,
      by_fabric: "",
      stockoperationorbyorder: "",
      sustainable: "",
      q: "",
    });
  };

  const [MenuItems, setMenuItems] = useState([]);
  const [CollectionItems, setCollectionItems] = useState([]);

  const divideMenuItems = () => {
    let foundStockOperation = false;
    const items = listOfCollection[0]?.value || [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.items === "Stock Operation") {
        foundStockOperation = true;
      }

      if (foundStockOperation) {
        setCollectionItems((prevCollectionItems) => [
          ...prevCollectionItems,
          {
            collection: item.collection,
            items: item.items,
            shortLink: item.shortLink,
          },
        ]);
      } else {
        setMenuItems((prevMenuItems) => [
          ...prevMenuItems,
          {
            collection: item.collection,
            items: item.items,
            shortLink: item.shortLink,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    divideMenuItems();
  }, [listOfCollection]);

  return (
    <ListOfMenu className="menu-content" ref={reference}>
      <button
        className="button-close-menu"
        onClick={() => closeCollectionMenu()}
      >
        <GrClose />
      </button>
      <div className="menu-items container ">
        <div className="menu-items-group">
          <p>{MenuItems[0]?.collection}</p>
          <div className="menu-items-smartphone">
            <ul>
              {MenuItems.map((item, k) => {
                return (
                  <li
                    className="items-space-top-bottom"
                    key={k}
                    onClick={() => {
                      handleShowMenu(),
                        handleMatchCollection(item.shortLink),
                        closeCollectionMenu();
                    }}
                    style={{
                      fontWeight: `${
                        filter.collection === MenuItems[0]?.collection &&
                        filter.item === item.items
                          ? "bold"
                          : ""
                      }`,
                    }}
                  >
                    {item.items}
                  </li>
                );
              })}
            </ul>
            <ul className="list-buttons-menu">
              {CollectionItems.map((item, k) => {
                return (
                  <li
                    className="items-space-top-bottom"
                    key={k}
                    onClick={() => {
                      handleShowMenu(),
                        handleMatchCollection(item.shortLink),
                        closeCollectionMenu();
                    }}
                    style={{
                      fontWeight: `${
                        filter.collection === MenuItems[0]?.collection &&
                        filter.item === item.items
                          ? "bold"
                          : ""
                      }`,
                    }}
                  >
                    {item.items}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {listOfCollection.map(
          (list, i) =>
            i >= 1 && (
              <div className="menu-items-group" key={i}>
                <p>{list.name}</p>
                <ul className="menu-list-for-groups">
                  {list.value.map((item, k) => {
                    return (
                      <li
                        className="items-space-top-bottom"
                        key={k}
                        onClick={() => {
                          handleShowMenu(),
                            handleMatchCollection(item.shortLink),
                            closeCollectionMenu();
                        }}
                        style={{
                          fontWeight: `${
                            filter.collection === list.name &&
                            filter.item === item.items
                              ? "bold"
                              : ""
                          }`,
                        }}
                      >
                        {item.items}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )
        )}
      </div>
    </ListOfMenu>
  );
};

export default MenuCollection;
