import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { SearchContext } from "../context/SearchContext";
import { FilterContext } from "../context/FilterContext";
import { GetQuerysContext } from "../context/GetQuerysContext";
import { sendSearchHistory } from "../services/api";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const getFilters = (params) => {
  if (params) {
    const filter = Object.fromEntries(params?.entries());

    return { ...filter };
  }
};

const MenuSearch = ({ user, handleOpenSearch }) => {
  const getDataSuggestions = useStaticQuery(graphql`
    query {
      allGoogleSheetWordToFilter {
        nodes {
          search
          result
        }
      }
    }
  `);

  const { AllQuery } = useContext(GetQuerysContext);

  const { setKeySearch, keySearch } = useContext(SearchContext);
  const { setDataProductsFilter } = useContext(FilterContext);
  useEffect(() => {
    setKeySearch({ nameKey: "", tagKey: [], tagButton: [] });
    localStorage.setItem("searchTerm", "");

    return () => {
      setDataProductsFilter([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeSearch = (event) => {
    const query = event.target.value;
    const queryWithRemoveSpace = query.split(" ").join(",");

    setKeySearch({ nameKey: queryWithRemoveSpace, tagKey: [], tagButton: [] });
    if (query.trim().length === 0) {
      event.target.value = "";
    }
  };

  const params = useMemo(() => new URLSearchParams(AllQuery), [AllQuery]);
  const [filter, setFilter] = useState(getFilters(params));
  const filterRef = useRef(filter);
  filterRef.current = filter;

  const updateURL = (newValues) => {
    Object.keys(newValues).forEach((key) => {
      let value = newValues[key];
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });
    navigate(`/products/?${params.toString()}`);
    // }
  };

  const onChangeFilterMenu = async (e, filter) => {
    e.preventDefault();

    await sendSearchHistory(localStorage.getItem("token"), {
      Date: new Date().toLocaleString(),
      user: user,
      search_key: keySearch.nameKey,
    })
      .then((response) => console.log(""))
      .catch(() => toast.error("Data not found, please try again"));
    updateURL(filter);
    setFilter(filter);
  };

  const handleOnSearch = (string, results) => {
    // if (results.length === 0) {
    //   setKeySearch({ nameKey: string, tagKey: [], tagButton: [] });
    // }

    const foundItem = getDataSuggestions.allGoogleSheetWordToFilter.nodes.find(
      (item) => item.search.toLowerCase() === string.toLowerCase().trim()
    );

    if (foundItem) {
      setKeySearch({ nameKey: foundItem.result, tagKey: [], tagButton: [] });
      localStorage.setItem("searchTerm", foundItem.search);
      // handleOpenSearch()
    } else {
      setKeySearch({ nameKey: string, tagKey: [], tagButton: [] });
      localStorage.setItem("searchTerm", string);
      // handleOpenSearch()
    }
  };

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result);
  };

  const handleOnSelect = (item) => {
    const query = item.result;

    setKeySearch({ nameKey: query, tagKey: [], tagButton: [] });
    localStorage.setItem("searchTerm", item.name);
    if (query.trim().length === 0) {
      item = "";
    }

    onChangeFilterMenu(
      { preventDefault: () => {} }, // Mock del evento para evitar errores
      {
        ...filter,
        q: query,
        collection: "",
        by_fabric: "",
        stockoperationorbyorder: "",
        sustainable: "",
      } // Actualizamos 'q' con el valor seleccionado
    );
    handleOpenSearch();
  };

  const handleOnFocus = () => {
    // console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  return (
    <>
      <div className="search-container showSearch" id="search-container">
        <div
          className="search-backdrop-content"
          onClick={() => handleOpenSearch()}
        ></div>
        <form
          className="search-box"
          onSubmit={(e) => {
            onChangeFilterMenu(e, {
              ...filter,
              q: keySearch.nameKey,
              collection: "",
              by_fabric: "",
              stockoperationorbyorder: "",
              sustainable: "",
            });
            handleOpenSearch();
          }}
        >
          <ReactSearchAutocomplete
            items={getDataSuggestions.allGoogleSheetWordToFilter.nodes.map(
              (x) => ({
                result: x.result,
                name: x.search,
              })
            )}
            // inputSearchString={keySearch.nameKey}
            // showIcon={false}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            on
            autoFocus={true}
            showNoResultsText="No suggestions"
            formatResult={formatResult}
            className="input-suggestions"
            maxResults={5}
            placeholder="Enter article number or keyword"
          />
        </form>

        {/* <input
          type="text"
          className="text search-input"
          onKeyUp={(e) => handleChangeSearch(e)}
          placeholder="Search Product..."
        /> */}
      </div>
    </>
  );
};

export default MenuSearch;
