import React from "react";
import ProductCardType from "./cards/ProductCardType";

const HomeTypeSection = ({ type }) => {
  const productType = {
    1: [
      { title: "Mare New Arrival", search: "SP-MARE" },
      {
        title: "Fade After Wash: Garment Wash to Create Vintage Look",
        search: "SP-FAW",
      },
      {
        title: "Takashi's Pick: Dot Air --Breathable & Light",
        search: "SP-DA",
      },
      { title: "Takashi's Pick: Shantung", search: "SP-SH" },
      { title: "New Arrivals", search: "SP-New" },
    ],
    2: [
      { title: "Shirting", search: "AmeCazi-Shirt" },
      { title: "Cotton Outer & Bottom", search: "AmeCazi-Outer" },
      { title: "Tech & Sports", search: "AmeCazi-Tech" },
      { title: "Suiting", search: "AmeCazi-Suit" },
      { title: "Indigo", search: "AmeCazi-Indigo" },
      { title: "New Arrivals", search: "AmeCazi-New" },
    ],
    3: [
      { title: "Cotton Wovens", search: "CL-CW" },
      { title: "Technical", search: "CL-Tech" },
      { title: "Suiting", search: "CL-Suit" },
      { title: "Drape", search: "CL-Drape" },
      { title: "Jersey", search: "CL-Jersey" },
      { title: "New Arrivals", search: "CL-New" },
    ],
    4: [
      { title: "Selvedge", search: "DNM-SL" },
      { title: "Novelty", search: "DNM-NV" },
      { title: "Shirting", search: "DNM-SH" },
      { title: "Piece Dyed", search: "DNM-PD" },
      { title: "Outer", search: "DNM-OT" },
      { title: "New Arrivals", search: "DNM-New" },
    ],
    5: [
      { title: "Cotton Woven", search: "N-CW" },
      { title: "Indigo & Denim", search: "N-ID" },
      { title: "Jersey", search: "N-Jersey" },
      { title: "Tech", search: "N-Tech" },
      { title: "Drape", search: "N-Drape" },
      { title: "Suit", search: "N-Suit" },
    ],
  };
  return (
    <div className="home-type">
      {productType[type]?.map((product) => (
        <ProductCardType title={product.title} search={product.search} />
      ))}
    </div>
  );
};

export default HomeTypeSection;
