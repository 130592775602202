import React, { useState, useEffect } from "react";
import useGetData from "../../hooks/useGetData";
import _ from "underscore";
import { ListOfMenu } from "./style";
import { GrClose } from "react-icons/gr";
import { IoIosArrowForward } from "react-icons/io";

const MenuByFabric = ({
  closeCollectionMenu,
  reference,
  onChangeFilter,
  handleShowMenu,
}) => {
  const getByFabric = useGetData();

  const byProfile = getByFabric.allGoogleSheetMenuByfabric.nodes;
  const items = _.groupBy(byProfile, "fabric");

  const [listByProfile, setListByProfile] = useState([]);

  useEffect(() => {
    _.map(items, (key, item) => {
      return setListByProfile((prevState) => [
        ...prevState,
        {
          name: item,
          value: _.map(_.groupBy(key, "subCategory"), (key2, value2) => {
            return [value2, key2];
          }),
        },
      ]);
    });
  }, []);

  const handleMatchByFabric = (shortlink) => {
    onChangeFilter({
      by_fabric: shortlink,
      collection: "",
      stockoperationorbyorder: "",
      sustainable: "",
      q: "",
    });
  };

  const [visibleGroups, setVisibleGroups] = useState({});

  const handleToggleVisibility = (index) => {
    setVisibleGroups((prevVisibleGroups) => ({
      ...prevVisibleGroups,
      [index]: !prevVisibleGroups[index],
    }));
  };
  return (
    <ListOfMenu className="menu-content" ref={reference}>
      <button
        className="button-close-menu"
        onClick={() => closeCollectionMenu()}
      >
        <GrClose />
      </button>
      <div className="menu-items container u-flex-wrap-wrap">
        {listByProfile.map((list, i) => (
          <div
            key={i}
            className="menu-items-group u-margin-none"
            style={{ width: "25%", paddingLeft: "3em" }}
          >
            <p>{list?.name}</p>
            <ul className="menu-list-for-groups">
              {list.value.map((item, k) => (
                <li
                  className={`u-flex-direction-column u-display-inline-flex byfabric-list`}
                  key={k}
                  style={{
                    display: visibleGroups[i] || k < 6 ? "block" : "none",
                  }}
                >
                  {item[0] !== "null" ? (
                    <div className="sub-category-text">
                      <span
                        onClick={() => {
                          handleShowMenu();
                          if (item[1].some(subItem => subItem.items === null)) {
                            handleMatchByFabric(item[1].find(subItem => subItem.items === null).shortLink);
                          }
                          closeCollectionMenu();
                        }}
                      >
                        {item[0]}
                      </span>
                      {item[1][0].items !== null ? (
                        <div className="u-display-inline-flex u-flex-direction-column sub-category-content">
                          {item[1].map((i, p) => (
                            <div key={p}>
                              {i.items !== null ? (
                                <small
                                  onClick={() => {
                                    handleShowMenu();
                                    handleMatchByFabric(i.shortLink);
                                    closeCollectionMenu();
                                  }}
                                >
                                  {i.items}
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {item[0] === "null" && item[1][0] !== "null" ? (
                    <div className="u-display-inline-flex u-flex-direction-column">
                      {item[1].map((i, w) => (
                        <small
                          key={w}
                          className="item-sub-category"
                          onClick={() => {
                            handleShowMenu();
                            handleMatchByFabric(i.shortLink);
                            closeCollectionMenu();
                          }}
                        >
                          {i.items}
                        </small>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              ))}
              {list.value.length > 6 && (
                <li>
                  <button className="see-more-button" onClick={() => handleToggleVisibility(i)}>
                    {visibleGroups[i] ? "Show less" : "Show more"}
                  </button>
                </li>
              )}
            </ul>
            <IoIosArrowForward className="filter-arrow" />
          </div>
        ))}
      </div>
    </ListOfMenu>
  );
};

export default MenuByFabric;
