import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Container } from "../components/styled/ProductsStyled";

import { ORDER_ID } from "../querys/index";
import { getImageFromDrive } from "../services/api";
import { UPDATE_ORDER } from "../mutations";
import { toast } from "react-toastify";
import { apiBase } from "../services/appenv";
import axios from "axios";
import { navigate } from "gatsby";

const InputField = ({ label, name, value, onChange, placeholder }) => (
  <div className="input-order-field">
    <label className="" htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className=""
    />
  </div>
);

const DetailProducts = ({ products, onChange }) => (
  <>
    {products.length === 0 ? (
      ""
    ) : (
      <h4 className="font-semibold mt-4" style={{ marginBottom: "0.5em" }}>
        Detail Products
      </h4>
    )}

    {products.map((product, index) => (
      <div key={index} className="">
        {/* <h4 className="font-semibold">Product {index + 1}</h4> */}
        <div className="u-display-flex u-flex-wrap">
          {product.image ? (
            <img
              src={getImageFromDrive(product.image)}
              alt="product Preview"
              className="thumbnail-order"
            />
          ) : (
            ""
          )}

          <div
            className="u-display-flex u-flex-wrap u-margin-left-1"
            style={{ width: "calc(100% - 100px)" }}
          >
            <InputField
              label="Article Number"
              name={`detailProducts.${index}.articleNumber`}
              value={product.articleNumber}
              onChange={onChange}
              placeholder="Article Number"
            />
            <InputField
              label="Color Number"
              name={`detailProducts.${index}.colorNumber`}
              value={product.colorNumber}
              onChange={onChange}
              placeholder="Color Number"
            />
            <InputField
              label="Quantity"
              name={`detailProducts.${index}.quantity`}
              value={product.quantity}
              onChange={onChange}
              placeholder="Quantity"
            />
            <InputField
              label="Total Price"
              name={`detailProducts.${index}.totalPrice`}
              value={product.totalPrice}
              onChange={onChange}
              placeholder="Total Price"
            />
            <InputField
              label="Regular Price"
              name={`detailProducts.${index}.regularPrice`}
              value={product.regularPrice}
              onChange={onChange}
              placeholder="Regular Price"
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

const DetailSwatches = ({ swatches, onChange }) => (
  <>
    {swatches.length === 0 ? (
      ""
    ) : (
      <h4 className="font-semibold mt-4" style={{ marginBottom: "0.5em" }}>
        Detail Swatch
      </h4>
    )}

    {swatches.map((swatch, index) => (
      <div key={index} className="orders-row-products">
        {/* <h4 className="font-semibold">Swatch {index + 1}</h4> */}
        <div className="u-display-flex u-flex-wrap">
          {swatch.image ? (
            <img
              src={getImageFromDrive(swatch.image)}
              alt="Swatch Preview"
              className="thumbnail-order"
            />
          ) : (
            ""
          )}

          <div
            className="u-display-flex u-flex-wrap u-margin-left-1"
            style={{ width: "calc(100% - 100px)" }}
          >
            <InputField
              label="Swatch Article Number"
              name={`detailSwatch.${index}.articleNumber`}
              value={swatch.articleNumber}
              onChange={onChange}
              placeholder="Swatch Article Number"
            />
            <InputField
              label="Swatch Color Number"
              name={`detailSwatch.${index}.colorNumber`}
              value={swatch.colorNumber}
              onChange={onChange}
              placeholder="Swatch Color Number"
            />
            <InputField
              label="Swatch Quantity"
              name={`detailSwatch.${index}.quantity`}
              value={swatch.quantity}
              onChange={onChange}
              placeholder="Swatch Quantity"
            />
            <InputField
              label="Swatch Total Price"
              name={`detailSwatch.${index}.totalPrice`}
              value={swatch.totalPrice}
              onChange={onChange}
              placeholder="Swatch Total Price"
            />
            <InputField
              label="Swatch Regular Price"
              name={`detailSwatch.${index}.regularPrice`}
              value={swatch.regularPrice}
              onChange={onChange}
              placeholder="Swatch Regular Price"
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

const AllOrdersID = ({ id }) => {
  const [orderID, setOrderID] = useState({});

  const { loading, error } = useQuery(ORDER_ID, {
    variables: { orderhistoryId: id },
    onCompleted: (data) => {
      setOrderID(data);
    },
  });

  const token = localStorage.getItem("token");
  const HEADERS = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const [updateOrderhistory] = useMutation(UPDATE_ORDER);
  const initializeFormData = (data) => ({
    amount: data.amount || "",
    email: data.email || "",
    username: data.username || "",
    shippingFee: data.shippingFee || "",
    companyName: data.companyName || "",
    dateOrder: data.dateOrder || "",
    payment_method: data.payment_method || "",
    expectedDeliveryDate: data.expectedDeliveryDate || "",
    shippingDetails: data.shippingDetails || "",
    shippingAddress: {
      address: data.shippingAddress?.address || "",
      city: data.shippingAddress?.city || "",
      shippingUser: data.shippingAddress?.shippingUser || "",
      phoneNumber: data.shippingAddress?.phoneNumber || "",
      zipcode: data.shippingAddress?.zipcode || "",
      street: data.shippingAddress?.street || "",
    },
    detailProducts: Array.isArray(data.detailProducts)
      ? data.detailProducts.map((product) => ({
          articleNumber: product.articleNumber || "",
          colorNumber: product.colorNumber || "",
          quantity: product.quantity || "",
          totalPrice: product.totalPrice || "",
          regularPrice: product.regularPrice || "",
          image: product.image || "",
        }))
      : [],
    detailSwatch: Array.isArray(data.detailSwatch)
      ? data.detailSwatch.map((swatch) => ({
          articleNumber: swatch.articleNumber || "",
          colorNumber: swatch.colorNumber || "",
          quantity: swatch.quantity || "",
          totalPrice: swatch.totalPrice || null,
          regularPrice: swatch.regularPrice || null,
          image: swatch.image || "",
        }))
      : [],
    status: data.status || "",
  });

  const [formData, setFormData] = useState(
    initializeFormData(orderID?.orderhistory?.data.attributes || {})
  );

  useEffect(() => {
    const newData = orderID?.orderhistory?.data.attributes || {};
    setFormData(initializeFormData(newData));
  }, [orderID]);

  const [orderDescription, setOrderDescription] = useState("");
  const [expectedDeliveryDateStatus, setExpectedDeliveryDateStatus] =
    useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingURL, setTrackingURL] = useState("");

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (name === "status") {
      setFormData({
        ...formData,
        status: value,
        ...(value !== "Shipped (from Japan)" &&
          value !== "Shipped (arrived in your country)" &&
          setExpectedDeliveryDateStatus(""),
        setTrackingNumber(""),
        setTrackingURL(""),
        setOrderDescription("")),
        
      });
    } else {
      setFormData((prevData) => {
        const newData = { ...prevData };
        let nested = newData;

        keys.forEach((key, idx) => {
          if (!isNaN(key)) {
            const arrayIndex = parseInt(key, 10);

            if (idx === keys.length - 1) {
              nested[arrayIndex] = value;
            } else {
              nested[arrayIndex] = nested[arrayIndex] || {};
              nested = nested[arrayIndex];
            }
          } else {
            if (idx === keys.length - 1) {
              nested[key] = value;
            } else {
              nested[key] = nested[key] || {};
              nested = nested[key];
            }
          }
        });

        return newData;
      });
    }
  };
  const handleEmailOrderStatus = () => {
    axios
      .post(
        `${apiBase}/api/mail-order-history`,
        JSON.stringify(orderData),
        HEADERS
      )
      .then((response) => {
        toast.success(`Email send successfully!`);
        // console.log(response, "response");
        navigate("/orders-history");
      })
      .catch((error) => {
        toast.error(`Error, please try again`);
      })
      .finally(() => {});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFields = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== orderID[key]) {
        updatedFields[key] = formData[key];
      }
    });

    try {
      await updateOrderhistory({
        variables: {
          id: id,
          data: updatedFields,
        },
      });
      handleEmailOrderStatus();
      toast.success("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    setShowAdditionalFields(e.target.checked);
  };

  const orderData = {
    status: formData.status,
    user: formData.user,
    email: formData.email,
    description: orderDescription,
    expectedDeliveryDateStatus,
    trackingNumber,
    trackingURL,
    expectedDeliveryDate: formData.expectedDeliveryDate,
    shippingDetails: formData.shippingDetails,
    order: id,
  };

  const shouldShowInputs =
    formData.status === "Shipped (from Japan)" ||
    formData.status === "Shipped (arrived in your country)";

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading order data</p>;

  return (
    <Container className="container">
      <div className="row">
        <h2 className="font-semibold mt-4 u-margin-bottom-1">
          Edit Order #{id}
        </h2>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-9">
            <div className="u-display-flex u-flex-wrap u-margin-bottom-1">
              <InputField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
              <InputField
                label="Amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Amount"
              />
              <InputField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Username"
              />
              <InputField
                label="Shipping Fee"
                name="shippingFee"
                value={formData.shippingFee}
                onChange={handleChange}
                placeholder="Shipping Fee"
              />
              <InputField
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Company Name"
              />
              <InputField
                label="Date Order"
                name="dateOrder"
                value={formData.dateOrder}
                onChange={handleChange}
                placeholder="Date Order"
              />
              <InputField
                label="Payment Method"
                name="payment_method"
                value={formData.payment_method}
                onChange={handleChange}
                placeholder="Payment Method"
              />
            </div>

            <h4 className="" style={{ marginBottom: "0.5em" }}>
              Shipping Address
            </h4>

            <div className="u-display-flex u-flex-wrap">
              <InputField
                label="Address"
                name="shippingAddress.address"
                value={formData.shippingAddress.address}
                onChange={handleChange}
                placeholder="Address"
              />
              <InputField
                label="City"
                name="shippingAddress.city"
                value={formData.shippingAddress.city}
                onChange={handleChange}
                placeholder="City"
              />
              <InputField
                label="Shipping User"
                name="shippingAddress.shippingUser"
                value={formData.shippingAddress.shippingUser}
                onChange={handleChange}
                placeholder="Shipping User"
              />
              <InputField
                label="Phone Number"
                name="shippingAddress.phoneNumber"
                value={formData.shippingAddress.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
              />
              <InputField
                label="Zipcode"
                name="shippingAddress.zipcode"
                value={formData.shippingAddress.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
              />
              <InputField
                label="Street"
                name="shippingAddress.street"
                value={formData.shippingAddress.street}
                onChange={handleChange}
                placeholder="Street"
              />
            </div>

            <h4
              className=""
              style={{ marginBottom: "0.5em", marginTop: "20px" }}
            >
              Shipping Details
            </h4>
            <div className="u-display-flex u-flex-wrap">
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <input
                  type="checkbox"
                  id="additionalFields"
                  onChange={handleCheckboxChange}
                  checked={showAdditionalFields}
                />
                <label
                  htmlFor="additionalFields"
                  style={{ marginLeft: "5px", fontSize: "12px" }}
                >
                  Add Shipping Details
                </label> */}
              </div>
              {/* {showAdditionalFields && ( */}
              <div style={{ display: "flex" }}>
                <div className="input-order-field">
                  <label htmlFor="expectedDeliveryDate">
                    Expected Delivery Date:
                  </label>
                  <input
                    type="date"
                    id="expectedDeliveryDate"
                    name="expectedDeliveryDate"
                    value={formData.expectedDeliveryDate}
                    onChange={handleChange}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "5px",
                      marginTop: "5px",
                    }}
                  />
                </div>

                <div className="input-order-field">
                  <label htmlFor="shippingDetails">Shipping Details</label>
                  <input
                    id="shippingDetails"
                    name="shippingDetails"
                    placeholder="Shipping Details"
                    value={formData.shippingDetails}
                    onChange={handleChange}
                    style={{
                      display: "block",
                      width: "300px",
                      height: "30px",
                      padding: "5px",
                      marginTop: "5px",
                      resize: "none",
                    }}
                  />
                </div>
              </div>
              {/* )} */}
            </div>

            <DetailProducts
              products={formData?.detailProducts}
              onChange={handleChange}
            />

            <DetailSwatches
              swatches={formData?.detailSwatch}
              onChange={handleChange}
            />
          </div>
          <div className="col-3 delivery-status">
            <div className="delivery-status__select">
              <div style={{ marginBottom: "0.5em" }}>Delivery Status</div>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className=""
              >
                <option value="">Select Status</option>
                <option value="Ordered">Ordered</option>
                <option value="Confirmed order">Confirmed order</option>
                <option value="Preparing Delivery">Preparing Delivery</option>
                <option value="Shipped (from Japan)">
                  Shipped (from Japan){" "}
                </option>
                <option value="Shipped (arrived in your country)">
                  Shipped (arrived in your country)
                </option>
                <option value="Arrived">Arrived</option>
              </select>

              {shouldShowInputs && (
                <div>
                  <div className="input-order-field">
                    <label htmlFor="expectedDeliveryDate">
                      Estimate delivery date:
                    </label>
                    <input
                      type="date"
                      id="expectedDeliveryDateStatus"
                      name="expectedDeliveryDateStatus"
                      value={expectedDeliveryDateStatus}
                      onChange={(e) =>
                        setExpectedDeliveryDateStatus(e.target.value)
                      }
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                  <div className="input-order-field">
                    <label htmlFor="trackingNumber">Tracking #:</label>
                    <input
                      type="text"
                      id="trackingNumber"
                      name="trackingNumber"
                      value={trackingNumber}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </div>

                  <div className="input-order-field">
                    <label htmlFor="trackingURL">Tracking URL:</label>
                    <input
                      type="text"
                      id="trackingURL"
                      name="trackingURL"
                      value={trackingURL}
                      onChange={(e) => setTrackingURL(e.target.value)}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
              )}

              <textarea
                name="orderDescription"
                id="orderDescription"
                placeholder="Description"
                onChange={(e) => setOrderDescription(e.target.value)}
                value={orderDescription}
                style={{
                  marginTop: "10px",
                  padding: "5px",
                  width: "100%",
                  height: "150px",
                  resize: "none",
                }}
              ></textarea>

              <button type="submit" className="order-button">
                Update Status
              </button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default AllOrdersID;
